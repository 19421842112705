import React, { useEffect, useState } from "react"
import { graphql, navigate } from "gatsby"
import styled from "styled-components"
import { Link, withPrefix } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { SectionPage, SectionHeading, Container, SectionTitle, MarkdownContent } from "../components/Section"
import RelatedProductCarousel from "../components/RelatedProductCarousel"
import CallToAction from "../components/CallToAction"

import QuotePopup from "../components/QuotePopup"
import ReviewSliderWidget from "../components/ReviewSliderWidget"
import Helmet from "react-helmet"
import ArrowIcon from "../components/Icons/ArrowIcon"
import { getProductUrl } from "../utils/product"
import { ListBadge } from "../sections/Product/Products/styles"
import Badge from "../components/Badge"

const Wrapper = styled.div`
  position: relative;
`
const BreadCrumb = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    margin-bottom: 30px;
  }
  @media (min-width: 992px) {
    font-size: 16px;
    line-height: 24px;
  }
  & a {
    text-decoration: none;
    color: #1e4156;
    &:hover {
      color: #ed602b;
    }
  }
  & span,
  & a {
    display: inline-block;
    vertical-align: middle;
  }
`
const GoBack = styled(Link)`
  color: #062c44;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  padding: 15px 0;
  &:hover {
    color: #ff3e00;
    cursor: pointer;
  }
  svg {
    transform: rotate(180deg);
  }
  span {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    line-height: 20px;
    margin-left: 10px;
  }
`
const BreadNav = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  position: relative;
  text-align: center;
  @media (min-width: 768px) {
    margin-right: auto;
  }
  & span {
    background: url(${props => props.bg});
    background-size: cover;
    position: relative;
    padding: 13px 50px;
    background: white;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    color: #1e4156;
    font-weight: 600;

    & span {
      padding: 0;
      color: #676b79;
      font-weight: 400;
      box-shadow: none;
      display: inline-block;
      margin-top: -4px;
    }
  }
`

const ProductDtl = styled.div`
  padding:0;
@media (min-width: 768px) {
  padding: 20px 0 0;
}
@media (min-width: 992px) {
  padding: 40px 0 0;
}
  @media (min-width: 1200px) {
    padding: 60px 0 0px;
  }
  @media (min-width: 1600px) {
    padding: 60px 0 0;
  }
`
const ProductTop = styled.div`
  padding: 0;
`
const ProductCrm = styled.div`
  position: relative;
  .d-none {
    display: none;
    opacity: 0;
  }

  .for-canvas-size {
    canvas {
      width: 100% !important;
      height: 95% !important;
    }
  }
`


const ListBox = styled.div`
  margin:0;
`
const ListTitle = styled.div`
  text-align: left;
  color: #062C44;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  padding-bottom: 5px;
`
const ListUse = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin:0 -3px 30px -3px;
`

const ListItemUse = styled.div`
  position: relative;
  padding:3px;
`

const LinkUse = styled.label`  
  display: inline-flex;
  color: #062c44;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  
`

const ProductTemplate = ({ data, location }) => {
  const [isQuoteVisible, setQuoteVisible] = useState(false)
  const [senseiProductId, setSenseiProductsId] = useState("")

  const handleModalClose = () => {
    setQuoteVisible(false)
    document.querySelector("body").classList.remove("modal-open")
  }

  const productData = data.contentfulProduct
  const homePageData = data.contentfulHome

  const TestimonialSectionData = homePageData.reviewSection
  const TestimonialData = data.allContentfulTestimonial

  const allProducts = data.allContentfulProduct.edges.filter(
    product =>
      product.node.productCategory &&
      product.node.productCategory.name &&
      product.node.productRoofType &&
      product.node.productRoofType.name &&
      product.node.productCategory.name === productData.productCategory.name &&
      product.node.id !== productData.id
  )
  const temp = productData.title.replace(/[^\w\s]+/gi, " ").replace(/  +/g, " ")
  const titleUrl = temp
    .split(" ")
    .join("-")
    .toLowerCase()

  let categoryUrl = productData.productCategory.name
    .split(" ")
    .join("-")
    .toLowerCase();

  if (categoryUrl === "commercial-buildings") categoryUrl = "commercial-metal-buildings";

  let roofTypeUrl = productData.productRoofType.name
    .split(" ")
    .join("-")
    .toLowerCase()

  const pl = getProductUrl(productData)
  const url = `https://www.probuiltsteel.com${pl}/`
  const uTypes = pl.split("/").filter(Boolean)

  const itemListElements = [
    {
      "@type": "ListItem",
      position: 1,
      name: "Home",
      item: "https://www.probuiltsteel.com/",
    },
    {
      "@type": "ListItem",
      position: 2,
      name: productData.productCategory.name,
      item: `https://www.probuiltsteel.com/${categoryUrl}/`,
    },
  ]

  if (uTypes.length === 3) {
    itemListElements.push({
      "@type": "ListItem",
      position: 3,
      name: productData.productRoofType.name,
      item: `https://www.probuiltsteel.com/${categoryUrl}/${roofTypeUrl}/`,
    })
  }

  itemListElements.push({
    "@type": "ListItem",
    position: itemListElements.length + 1,
    name: productData.title,
    item: url,
  })

  const breadcrumbSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: itemListElements,
  }

  const productSchema = {
    "@context": "https://schema.org",
    "@type": "Product",
    sku: "PB#" + productData.skuNumber,
    image: productData.image
      ? productData.image.fluid.src
      : productData.imageSrc,
    name: productData.title,
    description: productData.description.description,
    url: url,
    brand: {
      "@type": "Brand",
      name: "Probuilt Steel Buildings",
    },
    offers: {
      "@type": "Offer",
      url: url,
      itemCondition: "https://schema.org/NewCondition",
      availability: "https://schema.org/InStock",
      price: productData.price,
      priceCurrency: "USD",
      seller: {
        "@type": "Organization",
        name: "Probuilt Steel Buildings",
      },
      shippingDetails: {
        "@type": "OfferShippingDetails",
        shippingRate: {
          "@type": "MonetaryAmount",
          value: 0,
          currency: "USD",
        },
        shippingDestination: {
          "@type": "DefinedRegion",
          addressCountry: "US",
        },
        deliveryTime: {
          "@type": "ShippingDeliveryTime",
          handlingTime: {
            "@type": "QuantitativeValue",
            minValue: 0,
            maxValue: 1,
            unitCode: "DAY",
          },
          transitTime: {
            "@type": "QuantitativeValue",
            minValue: 1,
            maxValue: 2,
            unitCode: "DAY",
          },
        },
      },
    },
    review: {
      "@type": "Review",
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
      },
      author: {
        "@type": "Organization",
        name: "Probuilt Steel Buildings",
      },
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.6",
      reviewCount: "31",
    },
  }

  const schema = [breadcrumbSchema, productSchema]
  //console.log(schema, "Test schema");

  const isBrowser = typeof window !== `undefined`
  useEffect(() => {
    const productData = data.contentfulProduct
    const encodedSenseiProductId = new Buffer.from(
      productData.senseiProductId
    ).toString("base64")
    setSenseiProductsId(encodedSenseiProductId)
  }, [data.contentfulProduct])

  //console.log(senseiProductId, 'Sensei Product ID')

  const renderBackBtn = () => {
    let backUrl
    if (categoryUrl === "seneca-barns" || categoryUrl === "carolina-barns") {
      backUrl = `/barns/${categoryUrl}/`
    } else if (
      categoryUrl === "storage-buildings" ||
      categoryUrl === "metal-sheds" ||
      categoryUrl === "metal-warehouses" ||
      categoryUrl === "commercial-buildings"
    ) {
      backUrl = `/${categoryUrl}/`
    } else {
      backUrl = `/${categoryUrl}/${roofTypeUrl}/`
    }

    return (
      <GoBack to={backUrl} className="back">
        <ArrowIcon fill="#ED602B" />
        <span>Back</span>
      </GoBack>
    )
  }

  const renderBreadCrumb = () => {
    if (categoryUrl === "seneca-barns" || categoryUrl === "carolina-barns") {
      return (
        <span>
          <Link to="/">Home</Link>
          {` / `}
          <Link to="/barns/">Barns</Link>
          {` / `}
          <Link to={`/barns/${categoryUrl}/`}>
            {productData.productCategory.name}
          </Link>
          {` / `}
          <span>{productData.title}</span>
        </span>
      )
    }

    if (categoryUrl === "commercial-metal-buildings") {
      return (
        <span>
          <Link to="/">Home</Link>
          {` / `}
          <Link to={`/${categoryUrl}/`}>Commercial Metal Buildings</Link>
          {` / `}
          <span>{productData.title}</span>
        </span>
      )
    }

    if (
      categoryUrl === "commercial-buildings" ||
      categoryUrl === "storage-buildings" ||
      categoryUrl === "metal-warehouses"
    ) {
      return (
        <span>
          <Link to="/">Home</Link>
          {` / `}
          <Link to={`/${categoryUrl}/`}>
            {productData.productCategory.name}
          </Link>
          {` / `}
          <span>{productData.title}</span>
        </span>
      )
    }

    return (
      <span>
        <Link to="/">Home</Link>
        {` / `}
        <Link to={`/${categoryUrl}/`}>{productData.productCategory.name}</Link>
        {` / `}
        <Link to={`/${categoryUrl}/${roofTypeUrl}/`}>
          {productData.productRoofType.name}
        </Link>
        {` / `}
        <span>{productData.title}</span>
      </span>
    )
  }

  return (
    <Layout location={location} isShowProduct>
      <SEO
        title={productData.metaTitle}
        description={productData.metaDescription.metaDescription}
        schemaMarkup={schema}
      />
      {isBrowser && (
        <Helmet>
          <link
            href="/static/css/main.afade158.css"
            rel="stylesheet"
            data-product_id={senseiProductId}
          />
          <script
            src={withPrefix("crm.js")}
            data-product_id={senseiProductId}
            data-sku_id={productData.skuNumber}
            data-product_url={
              "https://www.probuiltsteel.com/" +
              categoryUrl +
              "/" +
              roofTypeUrl +
              "/" +
              titleUrl +
              "/"
            }
          ></script>
          <script
            src={withPrefix("/static/js/main.27618c2c.js")}
            data-product_id={senseiProductId}
          ></script>
        </Helmet>
      )}
      <Wrapper>
        <ProductDtl className="product-dtl">
          <ProductTop className="product-top">
            <div className="container">
              <BreadCrumb>
                {renderBackBtn()}
                <BreadNav>{renderBreadCrumb()}</BreadNav>
              </BreadCrumb>
            </div>
          </ProductTop>
          {isBrowser && senseiProductId !== "" && (
            <ProductCrm className="product-crm">
              <div id="crmsenseiId" className="d-none">
                {senseiProductId}
              </div>
              <div id="crmsenseiSkuId" className="d-none">
                {productData.skuNumber}
              </div>
              <div id="productUrl" className="d-none">
                {"https://www.probuiltsteel.com/" +
                  categoryUrl +
                  "/" +
                  roofTypeUrl +
                  "/" +
                  titleUrl}
              </div>
              <div id="root"></div>
            </ProductCrm>
          )}
        </ProductDtl>

        <SectionPage
          ept="60px"
          epb="60px"
          xpt="40px"
          xpb="40px"
          pt="30px"
          pb="30px"
          bg="#fff"
          bgAfter="#d9d9d9"
          bgAfterWidth="100%"
          bgAfterHeight="1px"
          bottomAfter="0"
          topAfter="auto"
          className="product-bottom"
        >
          <Container maxWdith="780px">
            <SectionTitle textAlign="left">About {productData.title}</SectionTitle>
            <MarkdownContent textAlign="left" maxWdith="100%"><p>{productData.description.description}</p></MarkdownContent>
            <ListBox>
              <ListTitle>Recommended Use</ListTitle>
              <ListUse>
                {productData.canBeUsedAs.map((item, i) => {
                  return (
                    <ListItemUse key={i}>
                      <LinkUse>{item.content} <i>, &nbsp;</i></LinkUse>
                    </ListItemUse>
                  )
                })}
              </ListUse>
              <ListTitle>Most Searched Tags</ListTitle>
              <ListBadge>
                {(productData.tags || []).map(item => (
                  <Badge
                    size="md"
                    key={item}
                    badgeText={item}
                    onClick={() =>
                      navigate("/shop-metal-buildings/", {
                        state: { tag: item },
                      })
                    }
                  />
                ))}
              </ListBadge>
            </ListBox>
          </Container>
        </SectionPage>

        {/* <SectionPage
          ept="0"
          epb="60px"
          xpt="0"
          xpb="40px"
          pt="0"
          pb="30px"
          bg="#fff"
          className="section-review"
        >
          <Container>
            <SectionHeading textAlign="left">Customer Reviews</SectionHeading>
            <ProductReviewWidget />
          </Container>
        </SectionPage> */}
        <ReviewSliderWidget
          sectionData={TestimonialSectionData}
          data={TestimonialData}
        />
        <SectionPage
          ept="80px"
          epb="80px"
          xpt="40px"
          xpb="60px"
          pt="30px"
          pb="60px"
          bg="#F4FBFF"
          className="section-similar"
        >
          <Container>
            <SectionHeading textAlign="center" mb="40px">
              Similar Buildings
            </SectionHeading>
            <RelatedProductCarousel data={allProducts} />
          </Container>
        </SectionPage>

      </Wrapper>
      <CallToAction />
      <QuotePopup
        isVisible={isQuoteVisible}
        onClose={handleModalClose}
        location={location}
        formName="Product Get Price Form"
      />
    </Layout>
  )
}

export default ProductTemplate

export const pageQuery = graphql`
  query productQuery($id: String!) {
    contentfulProduct(id: { eq: $id }) {
      id
      contentful_id
      skuNumber
      title
      senseiProductId
      metaTitle
      metaDescription {
        metaDescription
      }
      description {
        description
      }
      imageSrc
      image {
        fluid(maxWidth: 700, quality: 100) {
          base64
          sizes
          aspectRatio
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      width
      length
      height
      price
      tags
      priceDescription
      gauge
      productServices {
        service
        image {
          fluid {
            base64
            sizes
            aspectRatio
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        service
      }
      productRoofType {
        name
      }
      productCategory {
        name
      }
      numberOfSale
      canBeUsedAs {
        content
      }
    }
    Colors: allContentfulProductColor {
      edges {
        node {
          color
          image {
            fluid(maxWidth: 60, quality: 100) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
      }
    }
    allContentfulProduct(sort: { fields: skuNumber }) {
      edges {
        node {
          id
          senseiProductId
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          skuNumber
          priceDescription
          gauge
          productServices {
            spaceId
            service
            image {
              fluid(maxWidth: 35, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          description {
            description
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
        }
      }
    }
    allContentfulTestimonial {
      edges {
        node {
          customerName
          description {
            description
          }
        }
      }
    }
    contentfulHome {
      reviewSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
